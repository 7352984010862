<template>
  <div
    v-if="
      formComponent.type == 'text' ||
      formComponent.type == 'input' ||
      formComponent.type == 'password'
    "
  >
    <vs-input
      class="w-full"
      :type="formComponent.type"
      :placeholder="formComponent.placeholder"
      :disabled="formComponent.disable"
      v-model="formComponent.value"
      :max="formComponent.max"
      :min="formComponent.min"
      :data-date="formComponent.formatter"
      :formatter="formComponent.formatter"
      :readOnly="
        formComponent.disable != formComponent.readOnly ? formComponent.readOnly : false
      "
      @input="onInput"
      @blur="blur"
    />
  </div>
  <div v-else-if="formComponent.type == 'date'">
    <datepicker
      class="w-full"
      :inline="false"
      v-model="formComponent.value"
      :max="formComponent.max"
      :min="formComponent.min"
      :placeholder="formComponent.placeholder"
      :readOnly="
        formComponent.disable != formComponent.readOnly ? formComponent.readOnly : false
      "
      :format="formComponent.formatter"
    ></datepicker>
  </div>
  <div v-else-if="formComponent.type == 'textarea'">
    <vs-textarea
      class="w-full input-readonly"
      :disabled="formComponent.disable"
      :readonly="
        formComponent.disable != formComponent.readOnly ? formComponent.readOnly : false
      "
      :name="formComponent.name"
      v-model="formComponent.value"
      :placeholder="formComponent.placeholder"
      @input="onInput"
    />
  </div>
  <div v-else-if="formComponent.type == 'multiselect'">
    <multiselect
      class="selectExample"
      v-model="formComponent.select"
      :disabled="formComponent.disable"
      :options="formComponent.options"
      :multiple="formComponent.multiple"
      :allow-empty="formComponent.allowEmpty"
      :group-select="formComponent.groupSelect"
      :loading="
        formComponent.loading || formComponent.loading == false
          ? formComponent.loading
          : true
      "
      :max-height="formComponent.maxHeight ? formComponent.maxHeight : 100"
      :limit="formComponent.limit"
      :placeholder="formComponent.placeholder"
      :searchable="formComponent.searchable"
      :custom-label="formComponent.customLabel"
      style="bottom: unset !important"
      track-by="id"
      @open="multiselectOnOpen"
      @close="multiselectOnClose"
    >
    </multiselect>
  </div>
</template>

<script>
// import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {};
  },
  props: ["formComponent", "key1", "key2"],
  components: {
    Datepicker,
  },
  methods: {
    onInput(v) {
      this.formComponent.key1 = this.key1;
      this.formComponent.key2 = this.key2;
      this.$emit("onInput", v, this.formComponent);
    },
    blur(v) {
      this.formComponent.key1 = this.key1;
      this.formComponent.key2 = this.key2;
      this.$emit("blur", v.target._value, this.formComponent);
    },
    multiselectOnOpen() {
      this.$emit("open", this.formComponent);
    },
    multiselectOnClose() {
      this.$emit("close", this.formComponent);
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-con-table.stripe .footer-customer-order-top-line,
.footer-customer-order-top-line:hover {
  background: white !important;
  transform: translateY(0px) !important;
  border-top: 1px solid #ddd7d7 !important;
}
.vs-con-table.stripe .footer-customer-order,
.tr.vs-con-table.stripe.footer-customer-order:hover {
  background: white !important;
  transform: translateY(0px) !important;
}
</style>
