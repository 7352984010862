<template>
  <div class="w-full">
    <vs-table stripe border :sst="true" :data="dataLines" v-if="dataLines.length > 0">
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th :key="iComp" v-for="(trComp, iComp) in dataLines[0].components">{{
          trComp.label
        }}</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          v-show="tr.flag != 'delete'"
        >
          <vs-td>{{ tr.no ? tr.no : indextr + 1 }}</vs-td>
          <vs-td :key="iComp" v-for="(trComp, iComp) in tr.components"
            ><formInput
              :formComponent="trComp"
              :key1="indextr"
              :key2="iComp"
              @onInput="onInput"
              @blur="blur"
              @open="multiselectOpen"
              @close="multiselectClose"
          /></vs-td>
          <vs-td v-if="tr.showButtonAdd != false || tr.showButtonDel != false">
            <div v-if="tr.showButtonAdd">
              <vx-tooltip text="Add Item">
                <vs-button
                  size="small"
                  color="green"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="handleAddItem()"
                />
              </vx-tooltip>
            </div>
            <div v-else-if="tr.showButtonDel">
              <vx-tooltip text="Delete Item">
                <vs-button
                  size="small"
                  color="red"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="handleRemoveItem(indextr)"
                />
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        <vs-tr v-for="index in countLimit" :key="index + data.length"
          ><vs-td> </vs-td
        ></vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import formInput from "./form-input.vue";
export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-order/find-by-customer-order-id/",
      title: "Detail Customer Order",
      defaultLimit: 3,
      isOpenDropdown: false,
      countLimit: 3,
    };
  },
  components: {
    formInput,
  },
  props: ["dataLines"],
  methods: {
    multiselectOpen(component) {
      this.countLimit = component.limit;
      this.isOpenDropdown = !this.isOpenDropdown;
      this.$emit("multiselect-open", component);
    },
    onInput(v, comp) {
      this.$emit("onInput", v, comp);
    },
    blur(v, comp) {
      this.$emit("blur", v, comp);
    },
    multiselectClose(component) {
      if (this.isOpenDropdown) {
        this.countLimit = this.defaultLimit;
        this.isOpenDropdown = false;
      } else {
        this.isOpenDropdown = !this.isOpenDropdown;
      }
      this.$emit("multiselect-close", component);
    },
    handleAddItem() {
      this.$emit("item-add");
    },
    handleRemoveItem(index) {
      this.$emit("item-remove", index);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {},
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
