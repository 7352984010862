<template>
  <div class="m-6">
    <!-- <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    > -->
    <!-- <div class="vx-row">
      <div class="vx-col">
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div> -->
    <div class="vx-row mt-12">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Tolerance Code</label>
        <formInput :formComponent="fiToleranceCode" @onInput="fiOnInput" />
        <!-- <vs-input class="w-full" type="text" placeholder="Auto Generate" disabled /> -->
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Valid From</label>
        <formInput :formComponent="fiDateFrom" @onInput="fiOnInput" />
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Valid To</label>
        <formInput :formComponent="fiDateTo" @onInput="fiOnInput" />
      </div>
    </div>
    <div class="vx-row mt-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Note</label>
        <formInput :formComponent="fiNote" @onInput="fiOnInput" />
      </div>
    </div>
    <div class="vx-row mb-12 mt-12 w-full">
      <formLine
        :dataLines="dataLines"
        @onInput="fiOnInputLine"
        @blur="blur"
        @multiselect-open="handleMultiselectOpen"
        @multiselect-close="handleMultiselectClose"
        @item-add="handleAddItem"
        @item-remove="handleRemoveItem"
      />
    </div>
    <div class="vx-col text-right">
      <vs-button class="ml-4 mt-2" type="border" color="primary" v-on:click="formEdit"
        >Submit</vs-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import formInput from "./form-input.vue";
import formLine from "./form-line.vue";
export default {
  components: {
    formInput,
    formLine,
  },
  data() {
    return {
      baseUrl: "/api/v1/master/tolerance",
      title: "Create Tolerance",
      table: this.tableDefaultState(),
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      i_fi_risk_category: 0,
      i_fi_dist_channel: 1,
      i_fi_cust_category1: 2,
      i_fi_over_cl: 3,
      i_fi_day_overdue: 4,
      dataPrepare: 0,
      dataReady: 0,
      optionRiskCategories: [],
      optionDistCannels: [],
      optionCustCategories1: [],
      fiToleranceCode: {
        disable: false,
        readOnly: true,
        type: "text",
        name: "tolerance-code",
        value: "",
        placeholder: "Auto Generate",
      },
      fiNote: {
        disable: false,
        readOnly: false,
        type: "textarea",
        name: "note",
        value: "",
        placeholder: "Note",
      },
      fiDateTo: {
        disable: false,
        readOnly: false,
        type: "date",
        value: moment().format("YYYY-MM-DD"),
        formatter: (val) => {
          return moment(val).format("YYYY-MM-DD");
        },
        name: "date-to",
        min: moment().format("YYYY-MM-DD"),
        placeholder: "",
      },
      fiDateFrom: {
        disable: false,
        readOnly: false,
        type: "date",
        value: moment().format("YYYY-MM-DD"),
        name: "date-from",
        formatter: (val) => {
          return moment(val).format("YYYY-MM-DD");
        },
        min: moment().format("YYYY-MM-DD"),
        max: moment().format("YYYY-MM-DD"),
        placeholder: "",
      },
      dataLines: [],
      id: 0,
    };
  },
  props: ["toleranceID", "type_edit"],
  methods: {
    fiOnInput(v, component) {
      if (component.name == "date-from") {
        console.log("date-from", v);
        this.fiDateTo.min = v;
      } else if (component.name == "date-to") {
        console.log("date-to", v);
        this.fiDateFrom.max = v;
      }
    },
    fiOnInputLine(v, comp) {
      if (comp.name == "day_overdue") {
        var numb = v.match(/\d/g);
        console.log(numb);
        if (numb) {
          numb = numb.join("");
        }
        this.dataLines[comp.key1].components[comp.key2].value = numb;
      }
      console.log("component", comp);
      console.log("value", v);
      console.log("data", this.dataLines);
    },
    handleMultiselectOpen(component) {
      // console.log(component);
    },
    handleMultiselectClose(component) {
      // console.log(component);
    },
    setNoDataLines() {
      this.dataLines.forEach((v) => {});
      var no = 1;
      for (let i = 0; i < this.dataLines.length; i++) {
        if (this.dataLines[i].flag != "delete") {
          this.dataLines[i].no = no;
          no++;
        }
      }
    },
    blur(v, comp) {
      if (comp.name == "over_cl") {
        let numb = v.match(/[^+-]?\d+(\.\d+)?/g);
        if (numb) {
          numb.forEach((_, i) => {
            if (i != 0) {
              numb[i] = numb[i].match(/[^.]/g).join("");
              console.log("numb[i]=>", numb[i]);
            }
          });
          numb = numb.join("");
        }
        this.dataLines[comp.key1].components[comp.key2].value = numb;
      }
      // console.log("key1", comp.key1);
      // console.log("key2", comp.key2);
      // console.log("name", comp.name);
      // console.log("data", this.dataLines);
    },
    handleAddItem(dt = null) {
      var tempDataLine = this.templateDataLine();
      if (this.dataLines.length > 0) {
        tempDataLine.showButtonAdd = false;
        tempDataLine.showButtonDel = true;
      } else {
        tempDataLine.showButtonAdd = true;
        tempDataLine.showButtonDel = false;
      }
      tempDataLine.flag = "new";
      if (dt) {
        console.log("this.optionRiskCategories2", this.optionRiskCategories);
        const tempOptionSelectRiskCategories = this.optionRiskCategories.filter((v) => {
          console.log(
            "v.option_id",
            v.option_id,
            "<> dt.risk_category_id",
            dt.risk_category_id
          );
          return v.option_id == dt.risk_category_id;
        });

        const tempOptionSelectDistCannel = this.optionDistCannels.filter((v) => {
          return v.option_id == dt.customer_category_id;
        });

        const tempOptionSelectCustCat1 = this.optionCustCategories1.filter((v) => {
          return v.option_id == dt.customer_group_id;
        });

        tempDataLine.components[this.i_fi_risk_category].select =
          tempOptionSelectRiskCategories[0];
        tempDataLine.components[this.i_fi_dist_channel].select =
          tempOptionSelectDistCannel[0];
        tempDataLine.components[this.i_fi_cust_category1].select =
          tempOptionSelectCustCat1[0];

        tempDataLine.components[this.i_fi_over_cl].value = dt.persentage;
        tempDataLine.components[this.i_fi_day_overdue].value = dt.day_over_due;
        tempDataLine.flag = "edit";
        tempDataLine.tolerance_line_id = dt.ID;
      }
      this.dataLines.push(tempDataLine);
      this.setOffLoading(this.i_fi_cust_category1);
      this.setOffLoading(this.i_fi_dist_channel);
      this.setOffLoading(this.i_fi_risk_category);
      this.setNoDataLines();
    },
    handleRemoveItem(index) {
      this.dataLines[index].flag = "delete";
      this.setNoDataLines();
    },
    templateDataLine() {
      return {
        showButtonAdd: true,
        showButtonDel: false,
        tolerance_line_id: 0,
        flag: "edit",
        components: [
          {
            disable: false,
            readOnly: false,
            options: this.optionRiskCategories,
            multiple: false,
            allowEmpty: false,
            groupSelect: "",
            maxHeight: 200,
            limit: 10,
            searchable: true,
            select: null,
            customLabel: ({ code, name }) => {
              return `${code} ${name}`;
            },
            type: "multiselect",
            name: "risk_category",
            label: "Risk Category",
            placeholder: "Risk Category",
          },
          {
            disable: false,
            readOnly: false,
            options: this.optionDistCannels,
            multiple: false,
            allowEmpty: false,
            groupSelect: "",
            maxHeight: 200,
            limit: 10,
            searchable: true,
            select: null,
            customLabel: ({ code, name }) => {
              return `${code} ${name}`;
            },
            type: "multiselect",
            name: "distribution_channel",
            label: "Distribution Channel",
            placeholder: "Distribution Channel",
          },
          {
            disable: false,
            readOnly: false,
            options: this.optionCustCategories1,
            multiple: false,
            allowEmpty: false,
            groupSelect: "",
            maxHeight: 200,
            limit: 10,
            searchable: true,
            select: null,
            customLabel: ({ code, name }) => {
              return `${code} ${name}`;
            },
            type: "multiselect",
            name: "customer_category1",
            label: "Customer Category 1",
            placeholder: "Customer Category 1",
          },
          {
            disable: false,
            readOnly: false,
            type: "text",
            name: "over_cl",
            value: "",
            label: "% Over CL",
            placeholder: "% Over CL",
          },
          {
            disable: false,
            readOnly: false,
            type: "text",
            name: "day_overdue",
            value: "",
            label: "# Day Overdue",
            placeholder: "# Day Overdue",
          },
        ],
      };
    },
    setOptionRiskCategories() {
      this.dataLines.forEach((el, i) => {
        this.dataLines[i].components[
          this.i_fi_risk_category
        ].options = this.optionRiskCategories;
        this.dataLines[i].components[this.i_fi_risk_category].loading = false;
      });
    },
    setOptionDistCannels() {
      this.dataLines.forEach((el, i) => {
        this.dataLines[i].components[
          this.i_fi_dist_channel
        ].options = this.optionDistCannels;
        this.dataLines[i].components[this.i_fi_dist_channel].loading = false;
      });
    },
    setOptionCustomerCategory1() {
      this.dataLines.forEach((el, i) => {
        this.dataLines[i].components[
          this.i_fi_cust_category1
        ].options = this.optionCustCategories1;
        this.dataLines[i].components[this.i_fi_cust_category1].loading = false;
      });
    },
    setOffLoading(i_fi) {
      this.dataLines.forEach((el, i) => {
        this.dataLines[i].components[i_fi].loading = false;
      });
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      // this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      // this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      // this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      // this.getData();
    },
    handleClose() {
      // back to list
      // this.$router.push({ name: "customer-order" });
      this.$emit("handleClose");
    },
    reloadData() {
      // back to list
      // this.$router.push({ name: "customer-order" });
      this.$emit("reloadData");
    },
    getData() {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "/" + this.toleranceID).then((resp) => {
        console.log(resp.data);
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.fiDateFrom.value = moment(resp.data.valid_from).format("YYYY-MM-DD");
          this.fiDateFrom.max = moment(resp.data.valid_to).format("YYYY-MM-DD");
          this.fiDateTo.value = moment(resp.data.valid_to).format("YYYY-MM-DD");
          this.fiDateTo.min = moment(resp.data.valid_from).format("YYYY-MM-DD");
          this.fiNote.value = resp.data.note;
          this.fiToleranceCode.value = resp.data.code;
          this.dataLines = [];
          resp.data.tolerance_lines.forEach((el) => {
            this.handleAddItem(el);
          });
        } else {
          console.log(resp.data);
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    getOptionRiskCategory(type) {
      this.dataPrepare++;
      this.$http
        .get(this.baseUrl + "/risk-category/option", {
          params: {
            type: type,
          },
        })
        .then((resp) => {
          this.dataReady++;
          if (resp.code == 200) {
            if (resp.data) {
              var tempDataRiskCategory = {
                id: 0,
                code: "",
                option_id: 0,
                name: "ALL",
              };
              this.optionRiskCategories = [];
              this.optionRiskCategories.push(tempDataRiskCategory);
              resp.data.forEach((el) => {
                tempDataRiskCategory = {
                  id: 0,
                  code: "",
                  name: "",
                };
                tempDataRiskCategory.id = el.id;
                tempDataRiskCategory.option_id = el.id;
                tempDataRiskCategory.code = el.code;
                tempDataRiskCategory.name = el.name;

                this.optionRiskCategories.push(tempDataRiskCategory);
              });
              console.log("this.optionRiskCategories1", this.optionRiskCategories);
            }
            this.setOptionRiskCategories();
          } else {
            console.log(resp.data);
            // this.$vs.loading.close();
          }
        });
    },
    getOptionDistChannels() {
      this.dataPrepare++;
      this.$http.get(this.baseUrl + "/dist-channel/option").then((resp) => {
        this.dataReady++;
        if (resp.code == 200) {
          if (resp.data) {
            var tempDataDistCannel = {
              id: 0,
              code: "",
              option_id: 0,
              name: "ALL",
            };
            this.optionDistCannels = [];
            this.optionDistCannels.push(tempDataDistCannel);
            resp.data.forEach((el) => {
              tempDataDistCannel = {
                id: 0,
                code: "",
                name: "",
              };
              tempDataDistCannel.id = el.id;
              tempDataDistCannel.code = el.name;
              tempDataDistCannel.name = el.classification;
              tempDataDistCannel.option_id = el.id;

              this.optionDistCannels.push(tempDataDistCannel);
            });
          }
          this.setOptionDistCannels();
          console.log("this.optionDistCannels ", this.optionDistCannels);
        } else {
          console.log(resp.data);
          // this.$vs.loading.close();
        }
      });
    },
    getOptionCustCategories1() {
      this.dataPrepare++;
      this.$http
        .get(this.baseUrl + "/cust-group/option", {
          params: {
            level: 1,
          },
        })
        .then((resp) => {
          this.dataReady++;
          if (resp.code == 200) {
            if (resp.data) {
              var tempDataCustCategory1 = {
                id: 0,
                code: "",
                option_id: 0,
                name: "ALL",
              };
              this.optionCustCategories1 = [];
              this.optionCustCategories1.push(tempDataCustCategory1);
              resp.data.forEach((el) => {
                tempDataCustCategory1 = {
                  id: 0,
                  code: "",
                  name: "",
                };
                tempDataCustCategory1.id = el.id;
                tempDataCustCategory1.code = el.code;
                tempDataCustCategory1.name = el.name;
                tempDataCustCategory1.option_id = el.id;

                this.optionCustCategories1.push(tempDataCustCategory1);
              });
            }
            this.setOptionCustomerCategory1();
            console.log("this.optionCustCategories1 ", this.optionCustCategories1);
          } else {
            console.log(resp.data);
            // this.$vs.loading.close();
          }
        });
    },
    formEdit() {
      var dataPost = {},
        dataPostLine = [];
      var date_to = moment(this.fiDateTo.value);
      var date_from = moment(this.fiDateFrom.value);
      var nextCreate = true;
      var BreakException = {
        message: "",
        code: "",
      };
      dataPost.type_action = this.type_edit;
      try {
        if (date_from.isValid()) {
          dataPost.valid_from = date_from.format("YYYY-MM-DD");
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: this.fiDateFrom.name + " is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          BreakException.message = "error validation";
          BreakException.state = this.fiDateFrom.name;
          throw BreakException;
        }
        if (date_to.isValid()) {
          dataPost.valid_to = date_to.format("YYYY-MM-DD");
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: this.fiDateTo.name + " is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          BreakException.message = "error validation";
          BreakException.state = this.fiDateTo.name;
          throw BreakException;
        }
        this.dataLines.forEach((dt, i) => {
          dataPostLine.flag = "create";
          if (dt.components) {
            var tempRC, tempDC, tempCC1, tempOC, tempDOD;
            if (dt.components[this.i_fi_risk_category].select) {
              tempRC = dt.components[this.i_fi_risk_category].select.id;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_risk_category].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (dt.components[this.i_fi_dist_channel].select) {
              tempDC = dt.components[this.i_fi_dist_channel].select.id;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_dist_channel].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (dt.components[this.i_fi_cust_category1].select) {
              tempCC1 = dt.components[this.i_fi_cust_category1].select.id;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_cust_category1].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (dt.components[this.i_fi_over_cl].value) {
              tempOC = dt.components[this.i_fi_over_cl].value;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_over_cl].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (dt.components[this.i_fi_day_overdue].value) {
              tempDOD = dt.components[this.i_fi_day_overdue].value;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_day_overdue].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (
              (!tempRC && tempRC != 0) ||
              (!tempDC && tempRC != 0) ||
              (!tempCC1 && tempRC != 0) ||
              !tempOC ||
              !tempDOD
            ) {
              BreakException.message = "error validation";
              BreakException.index = i;
              throw BreakException;
            }
            if (dt.components[this.i_fi_day_overdue].value) {
              tempDOD = dt.components[this.i_fi_day_overdue].value;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: dt.components[this.i_fi_day_overdue].name + " is required",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            var tempDataPostLine = {
              risk_category_id: tempRC,
              distribution_channel_id: tempDC,
              customer_category_id1: tempCC1,
              over_cl: parseFloat(tempOC),
              id: dt.tolerance_line_id,
              flag: dt.flag,
              day_overdue: parseInt(tempDOD),
            };
            console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", dt);
            if (tempDataPostLine.over_cl > 100) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text:
                  dt.components[this.i_fi_over_cl].name +
                  " value must be lower than equal 100",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              throw BreakException;
            }
            dataPostLine.push(tempDataPostLine);
          }
        });
      } catch (error) {
        console.log(error.message);
        nextCreate = false;
      }
      dataPost.note = this.fiNote.value;
      dataPost.line = dataPostLine;
      if (nextCreate) {
        console.log(dataPost);
        this.$vs.loading();
        this.$http
          .post(this.baseUrl + "/update/" + this.toleranceID, dataPost)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              console.log(resp.data);
              setTimeout(() => {
                this.$emit("submited");
              }, 500);
            } else {
              console.log(resp.data);
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          });
      }
    },
    // customLableIncoterms({ id, text }) {
    //   return `${text}`;
    // },
    getDataCreditRisk() {},
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    dataPrepare() {
      this.$vs.loading();
    },
    dataReady() {
      this.$vs.loading.close();
      if (this.dataReady == this.dataPrepare) {
        this.getData();
      }
    },
  },
  mounted() {
    this.id = this.coID;
    this.dataLines.push(this.templateDataLine());
    this.getOptionRiskCategory("all");
    this.getOptionDistChannels();
    this.getOptionCustCategories1();
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
<style>
.multiselect--above .multiselect__content-wrapper {
  bottom: unset !important;
}
</style>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-con-table.stripe .footer-customer-order-top-line,
.footer-customer-order-top-line:hover {
  background: white !important;
  transform: translateY(0px) !important;
  border-top: 1px solid #ddd7d7 !important;
}
.vs-con-table.stripe .footer-customer-order,
.tr.vs-con-table.stripe.footer-customer-order:hover {
  background: white !important;
  transform: translateY(0px) !important;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: unset !important;
}
</style>
