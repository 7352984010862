<template>
  <vx-card :title="title">
    <div
      class="mb-12 w-full"
      v-if="
        action == 'create' || action == 'edit' || action == 'amend' || action == 'view'
      "
    >
      <!-- form -->
      <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
        >Back</vs-button
      >
    </div>
    <div class="mb-12 w-full" v-if="action == 'create'">
      <!-- form -->
      <FormCreateComponent
        @handleClose="handleClose"
        @submited="handleSubmitCreate"
      ></FormCreateComponent>
    </div>
    <div class="mb-12 w-full" v-else-if="action == 'edit'">
      <!-- form -->
      <FormEditComponent
        @handleClose="handleClose"
        @submited="handleSubmitEdit"
        :toleranceID="toleranceID"
        :type_edit="'normal'"
      ></FormEditComponent>
    </div>
    <div class="mb-12 w-full" v-else-if="action == 'amend'">
      <!-- form -->
      <FormEditComponent
        @handleClose="handleClose"
        @submited="handleSubmitEdit"
        :toleranceID="toleranceID"
        :type_edit="'amend'"
      ></FormEditComponent>
    </div>
    <div class="mb-12 w-full" v-else-if="action == 'view'">
      <!-- form -->
      <FormViewComponent
        @handleClose="handleClose"
        :toleranceID="toleranceID"
      ></FormViewComponent>
    </div>
    <div class="vx-row mb-12" v-else>
      <vs-button
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-tabs :value="lastITab">
        <vs-tab @click="handleChangeTab(iTabDraft)" label="Draft">
          <div class="con-tab-ejemplo">
            <TabDraftComponent
              :dataTable="dataTolerances[iTabDraft - 1]"
              @search="handleSearch"
              @activate="handleActivate"
              @cancel="handleCancel"
              @edit="handleEdit"
              @amend="handleAmend"
              @change-page="handleChangePage"
              @sort="handleSort"
              @change-length="handleChangelength"
              :i_tab="iTabDraft"
            />
          </div>
        </vs-tab>
        <vs-tab @click="handleChangeTab(iTabActive)" label="Active">
          <div class="con-tab-ejemplo">
            <TabDraftComponent
              :dataTable="dataTolerances[iTabActive - 1]"
              @search="handleSearch"
              :i_tab="iTabActive"
              @in-activate="handlerInActive"
              @amend="handleAmend"
              @change-page="handleChangePage"
              @sort="handleSort"
              @change-length="handleChangelength"
              @view="handleView"
            />
          </div>
        </vs-tab>
        <vs-tab @click="handleChangeTab(iTabInActive)" label="In-Active">
          <div class="con-tab-ejemplo">
            <TabDraftComponent
              :dataTable="dataTolerances[iTabInActive - 1]"
              @search="handleSearch"
              :i_tab="iTabInActive"
              @change-page="handleChangePage"
              @sort="handleSort"
              @change-length="handleChangelength"
              @view="handleView"
            />
          </div>
        </vs-tab>
        <vs-tab @click="handleChangeTab(iTabCanceled)" label="Canceled">
          <div class="con-tab-ejemplo">
            <TabDraftComponent
              :dataTable="dataTolerances[iTabCanceled - 1]"
              @search="handleSearch"
              :i_tab="iTabCanceled"
              @change-page="handleChangePage"
              @sort="handleSort"
              @change-length="handleChangelength"
              @view="handleView"
            />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import FormCreateComponent from "./form/create.vue";
import FormEditComponent from "./form/edit.vue";
import FormViewComponent from "./form/view.vue";
import TabDraftComponent from "./draft.vue";
export default {
  components: {
    FormCreateComponent,
    TabDraftComponent,
    FormEditComponent,
    FormViewComponent,
  },
  data() {
    return {
      title: "",
      Title1: "Tolerance",
      Title2: "Form Create",
      Title3: "Form Edit",
      Title4: "Form Amend",
      Title5: "Detail",
      dataFinal: [
        {
          key: "1-",
          waiting: false,
          data: [],
        },
      ],
      baseUrl: "/api/v1/master/tolerance",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      action: null,
      deleteId: null,
      toleranceID: null,
      reqCount: 0,
      tabIndex: null,
      iTabDraft: 1,
      iTabActive: 2,
      iTabInActive: 3,
      iTabCanceled: 4,
      reqSource: null,
      isDefault: 0,
      i_update_sts: 0,
      intervalReloadData: 500,
      lastITab: 0,
      defaultLength: 10,
      totalTab: 4,
      countLoading: 0,
      dataTolerances: [],
    };
  },

  methods: {
    initialState() {},
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        tab: 0,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.dataTolerances[this.lastITab].search = searching;
      this.dataTolerances[this.lastITab].page = 1;
      console.log("searching=>", searching);
      if (searching == "") {
        if (this.countLoading > 0) {
          this.$vs.loading.close();
          this.countLoading = -1;
        }
        this.setDataTableTolerance(this.lastITab);
        return;
      }
      this.getData(true);
    },
    handleChangePage(page) {
      this.dataTolerances[this.lastITab].page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.dataTolerances[this.lastITab].order = key;
      this.dataTolerances[this.lastITab].sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.dataTolerances[this.lastITab].length =
        val == "All" ? this.dataTolerances[this.lastITab].total : val;
      this.dataTolerances[this.lastITab].page = 1;
      this.getData();
    },
    handleClose() {
      // this.getData();
      this.action = "close";
      this.title = this.Title1;
    },
    handleCreate() {
      this.action = "create";
      this.title = this.Title2;
    },
    handleEdit(i_dt) {
      this.action = "edit";
      this.title = this.Title3;
      this.toleranceID = this.dataTolerances[this.lastITab].data[i_dt].id;
      console.log(this.toleranceID);
    },
    handleAmend(i_dt) {
      this.action = "amend";
      this.title = this.Title4;
      this.toleranceID = this.dataTolerances[this.lastITab].data[i_dt].id;
      console.log(this.toleranceID);
    },
    handleView(i_dt) {
      this.action = "view";
      this.title = this.Title5;
      this.toleranceID = this.dataTolerances[this.lastITab].data[i_dt].id;
      console.log(this.toleranceID);
    },

    getData(temp = false) {
      var iTab = this.lastITab + 1;
      console.log(this.reqCount);
      // this.cancelReq(msg_cancel);
      // this.reqCount++;
      // this.$vs.loading();
      // const CancelToken = axios.CancelToken;
      // this.reqSource = CancelToken.source();
      if (this.getDataFinal().waiting) {
        console.log("waiting find data tolerance...");
        return;
      }

      const tab = this.lastITab;
      if (this.getLengthDataFinal() > 0 && temp) {
        //untuk fitur search biar g lambat
        this.setDataTableTolerance(tab);
        if (this.countLoading > 0) {
          this.$vs.loading.close();
          this.countLoading = -1;
        }
        console.log("use temporary data tolerance");
        return;
      }
      this.setWaitingDataFinal();
      if (this.countLoading == 0) {
        this.$vs.loading();
      }
      this.countLoading++;
      const search = this.dataTolerances[tab].search;
      this.$http
        .get("/api/v1/master/tolerance", {
          params: {
            length: this.dataTolerances[tab].length,
            page: this.dataTolerances[tab].page,
            search: search,
            order: this.dataTolerances[tab].order,
            sort: this.dataTolerances[tab].sort,
            tab: iTab,
          },
          // cancelToken: this.reqSource.token,
        })
        .then((resp) => {
          console.log(resp);
          this.countLoading--;
          if (this.countLoading == 0 && this.countLoading != -1) {
            this.$vs.loading.close();
          } else if (this.countLoading == -1) {
            this.countLoading = 0;
          }
          if (resp.status != "cancel") {
            if (resp.code == 200) {
              var data = [];
              data = resp.data.records;
              console.log("log1", data.length);
              this.setDataFinal(data, search, tab);
              if (search == this.dataTolerances[tab].search) {
                this.dataTolerances[tab].total = resp.data.total_record;
                this.dataTolerances[tab].totalPage = resp.data.total_page;
                this.dataTolerances[tab].totalSearch = resp.data.total_record_search;
                this.setDataTableTolerance(tab);
                this.setStartEnd();
              }
              console.log("Data Final=>", this.dataFinal);
              console.log("log2");
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            console.log("this.dataTolerances[iTabDraft-1]", this.dataTolerances[tab]);
          } else {
            console.log("is canceled " + resp.message);
          }
        })
        .catch(function (thrown) {
          // alert(thrown.message);
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: thrown.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },

    //untuk controll pemanggilan API, agar tidak terjadi pemanggilan yg berulang-ulang / mubazir😅
    setWaitingDataFinal() {
      var key = this.generateKey(this.lastITab);
      var freshData = this.dataFinal.filter((v) => {
        return key != v.key; //delete old data
      });
      freshData.push({
        key: key,
        waiting: true,
        data: [],
      });
      this.dataFinal = freshData;
    },
    setDataFinal(data = [], search = "", tab = 0) {
      var key = this.generateKey(tab, search);
      var freshData = this.dataFinal.filter((v) => {
        return key != v.key; //delete old data
      });
      var tempData = [];
      data.forEach((v) => {
        tempData.push(v);
      });
      freshData.push({
        key: key,
        waiting: false,
        data: tempData,
      });
      console.log("freshData=>", freshData);
      this.dataFinal = freshData;
    },
    setDataTableTolerance(tab = 0) {
      this.dataTolerances[tab].data = this.getDataFinal().data;
      this.dataTolerances[tab].tab = this.lastITab;
    },
    getDataFinal() {
      var key = this.generateKey(this.lastITab);
      console.log("key=>", key);
      console.log("this.lastITab=>", this.lastITab);
      var findData = this.dataFinal.filter((v) => {
        return key == v.key;
      });
      if (findData.length > 0) {
        return findData[0];
      }
      return { key: "", waiting: false, data: [] };
    },
    getLengthDataFinal() {
      var key = this.generateKey(this.lastITab);
      var findData = this.dataFinal.filter((v) => {
        return key == v.key;
      });
      var length = 0;
      if (findData.length > 0) {
        length = findData[0].data.length;
      } else {
        length = 0;
      }
      return length;
    },

    generateKey(tab = 0, search = "") {
      if (search == "") {
        search = this.dataTolerances[tab].search;
      }
      var key = tab + "-" + search;
      return key;
    },
    handleActivate(id) {
      this.i_update_sts = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text:
          "Please confirm to activate this data, code " +
          this.dataTolerances[this.lastITab].data[this.i_update_sts].code,
        accept: this.acceptActivate,
      });
    },
    acceptActivate(confirm = false) {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/update", {
          id: this.dataTolerances[this.lastITab].data[this.i_update_sts].id,
          ignore: confirm,
          tab: this.iTabActive,
        })
        .then((resp) => {
          this.dataTolerances[this.lastITab].ignore = confirm;
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully activate",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            setTimeout(() => {
              this.getData();
            }, this.intervalReloadData);
          } else if (resp.data.confirm) {
            this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: `Confirm`,
              text: resp.message,
              accept: () => {
                this.acceptActivate(true);
              },
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.dataTolerances[this.lastITab].ignore = false;
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handlerInActive(id) {
      this.i_update_sts = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text:
          "Please confirm to inactivate this data, code " +
          this.dataTolerances[this.lastITab].data[this.i_update_sts].code,
        accept: this.acceptInActivate,
      });
    },
    acceptInActivate() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/update", {
          id: this.dataTolerances[this.lastITab].data[this.i_update_sts].id,
          tab: this.iTabInActive,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully in-activate",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            setTimeout(() => {
              this.getData();
            }, this.intervalReloadData);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleCancel(id) {
      this.i_update_sts = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text:
          "Please confirm to cancel this data, code " +
          this.dataTolerances[this.lastITab].data[this.i_update_sts].code,
        accept: this.acceptCancel,
      });
    },
    acceptCancel() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/update", {
          id: this.dataTolerances[this.lastITab].data[this.i_update_sts].id,
          tab: this.iTabCanceled,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully canceled",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            setTimeout(() => {
              this.getData();
            }, this.intervalReloadData);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    cancelReq(msg = "") {
      if (this.reqSource) {
        if (this.reqCount > 0) {
          this.reqSource.cancel(msg);
          this.reqCount--;
        }
      }
    },
    setStartEnd() {
      console.log("log1.1");
      let valStart =
        this.dataTolerances[this.lastITab].length *
          this.dataTolerances[this.lastITab].page -
        this.dataTolerances[this.lastITab].length +
        1;
      if (valStart > this.dataTolerances[this.lastITab].total) {
        valStart = 1;
      }
      if (this.dataTolerances[this.lastITab].total == 0) {
        valStart = 0;
      }
      let valEnd =
        this.dataTolerances[this.lastITab].length *
        this.dataTolerances[this.lastITab].page;
      if (valEnd > this.dataTolerances[this.lastITab].total) {
        valEnd = this.dataTolerances[this.lastITab].total;
      }
      if (
        this.dataTolerances[this.lastITab].totalSearch <
        this.dataTolerances[this.lastITab].total
      ) {
        valEnd = this.dataTolerances[this.lastITab].totalSearch;
      }

      this.dataTolerances[this.lastITab].start = valStart;
      this.dataTolerances[this.lastITab].end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      if (id == this.isDefault) {
        this.$vs.dialog({
          type: "alert",
          color: "danger",
          title: `Information`,
          text: "This tax is set to default, you can't delete this.",
        });
      } else {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this data",
          accept: this.acceptDelete,
        });
      }
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleChangeTab(i) {
      // i = 1 (draft)
      // i = 2 (active)
      // i = 3 (in active)
      // i = 4 (cenceled)
      console.log("tabIndex", i);
      this.lastITab = i - 1;
      this.dataTolerances[this.lastITab].length = this.defaultLength;
      this.dataTolerances[this.lastITab].search = "";
      this.dataTolerances[this.lastITab].data = [];
      this.getData();
    },
    handleSubmitCreate() {
      this.getData(this.lastITab);
      this.title = this.Title1;
      this.handleClose();
    },
    handleSubmitEdit() {
      this.getData(this.lastITab);
      this.title = this.Title1;
      this.handleClose();
    },
  },
  watch: {},
  mounted() {
    for (let i = 0; i < this.totalTab; i++) {
      this.dataTolerances.push(this.tableDefaultState());
    }
    this.lastITab = this.iTabDraft - 1;
    this.dataTolerances[this.lastITab].data = [];
    this.dataTolerances[this.lastITab].search = "";
    this.getData();
    this.title = this.Title1;
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
